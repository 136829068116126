import { render, staticRenderFns } from "./Home.vue?vue&type=template&id=4b55b914&"
var script = {}


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vue-cli-plugin-import-components */
import Navbar from "@/components/Navbar.vue";
import Hero from "@/components/Hero.vue";
import Services from "@/components/Services.vue";
import Features from "@/components/Features.vue";
import Faq from "@/components/Faq.vue";
import Footer from "@/components/Footer.vue";
script.components = Object.assign({}, { Navbar, Hero, Services, Features, Faq, Footer }, script.components);
import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=4aafa366&"
var script = {}


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vue-cli-plugin-import-components */
import Footer from "@/components/Footer.vue";
script.components = Object.assign({}, { Footer }, script.components);
//
//
//
//
//
//

export default {
  metaInfo: {
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      {
        hid: 'description',
        name: 'description',
        content:
          'Tenha acesso a uma rede de advogados especializados em previdência social, garantindo um valor justo de aposentadoria através de um processo simplificado. Deixe a burocracia para nós e preocupe-se em planejar seus sonhos.',
      },
    ],
    link: [
      { rel: 'stylesheet', href: '/assets/css/magnific-popup.css' },
      { rel: 'stylesheet', href: '/assets/css/boxicons.min.css' },
      { rel: 'stylesheet', href: '/assets/css/owl.carousel.min.css' },
      { rel: 'stylesheet', href: '/assets/css/owl.theme.default.min.css' },
      { rel: 'stylesheet', href: '/assets/css/bootstrap.min.css' },
      { rel: 'stylesheet', href: '/assets/css/style.css' },
    ],
    script: [
      { body: true, src: '/assets/js/jquery-3.5.1.min.js' },
      { body: true, src: '/assets/js/bootstrap.bundle.min.js' },
      { body: true, src: '/assets/js/scrollspy.min.js' },
      { body: true, src: '/assets/js/jquery.easing.min.js' },
      { body: true, src: '/assets/js/owl.carousel.min.js' },
      { body: true, src: '/assets/js/jquery.magnific-popup.min.js' },
      { body: true, src: '/assets/js/magnific.init.js' },
      { body: true, src: '/assets/js/app.js' },
    ],
  },
}
